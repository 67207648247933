
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.box-shadow {
	border: 1px solid #ccc;
	box-shadow: 4px 4px 4px #ccc;
	margin: 0px 3px;
}